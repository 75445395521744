@use '@shared/util-styling/mixins';
@use '@shared/util-styling/tokens';

.toggle-icon {
  background-color: var(--mdrt-color-backgrounds-bg-secondary-fill);
  border-radius: var(--mdrt-corner-radius-l);
  flex-shrink: 0;
  height: 24px;
  margin: 0 4px;
  max-width: 24px;
}

.accordion {
  width: 100%;

  &__plus-minus {
    & button {
      align-items: center;
      display: flex;
      padding: var(--mdrt-spacing-12) 0;
    }

    & span {
      font: var(--mdrt-title-3);
    }
  }

  &__label {
    font: var(--mdrt-headline-4);
  }

  &__trigger {
    @include mixins.button-reset;

    display: flex;
    width: 100%;

    &--spaced-out {
      justify-content: space-between;
    }
  }

  &__trigger-icon {
    margin-right: var(--spacing-4);
    width: 24px;

    &--is-open {
      transform: rotate(90deg);
    }
  }
}
